var modules = {
    run: function () {
        if (document.querySelector('[data-mod="pagination"]')) {
            import('./pagination/pagination').then((module) => {
                module.paginationMod.run();
            });
        }
    }
};

export { modules };