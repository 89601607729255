import { ds } from './../ds';
import { events } from './../events';
import { DSsysLibActionClass } from './ActionClass';

if (!window.DSsys) {
    var DSsys = {};
}
if (!DSsys.lib) {
    DSsys.lib = {};
}
DSsys.lib.ActionContainer = function()
{
    var that = this;
    this.actions = {};
    this.beforeActionsFns = {};
    this.builtInActions = [
        'asyncModal',
        'asyncRefreshImage',
        'asyncUrl',
        'autoLunchModal',
        'modal',
        'showIfEmptyValue',
        'submitForm',
        'tabs'
    ];
    events.register('actions:runBuiltIn', function (elmn) {
        that.runBuiltIn(elmn);
    });
};
DSsys.lib.ActionContainer.prototype.registerAction = function(action, fn)
{
    this.actions[action] = fn;
};
DSsys.lib.ActionContainer.prototype.getRegisteredAction = function(action)
{
    return typeof this.actions[action] === 'undefined' ? null : this.actions[action];
};
DSsys.lib.ActionContainer.prototype.registerBeforeActionFn = function(action, name, fn)
{
    if (typeof this.beforeActionsFns[action] === 'undefined') {
        this.beforeActionsFns[action] = {};
    }
    this.beforeActionsFns[action][name] = fn;
};
DSsys.lib.ActionContainer.prototype.getRegisteredBeforeActionFn = function(action, name)
{
    return typeof this.beforeActionsFns[action] === 'undefined' || typeof this.beforeActionsFns[action][name] === 'undefined' ? null : this.beforeActionsFns[action][name];
};
DSsys.lib.ActionContainer.prototype.getActionElements = function(root)
{
    var that = this;
    var actionElements = {};
    var addElements = function (root) {
        that.builtInActions.forEach(function (action) {
            var els = root.querySelectorAll('[data-ds-action~="' + action + '"]');
            if (typeof actionElements[action] === 'undefined') {
                actionElements[action] = [];
            }
            els.forEach(function (elmn) {
                actionElements[action].push(elmn);
            });
        });
    };
    if (root instanceof Array || root instanceof NodeList) {
        for (var i = 0; i < root.length; i++) {
            addElements(root[i]);
        }
    } else if (root instanceof Element) {
        addElements(root);
    } else {
        addElements(document);
    }
    return actionElements;
};
DSsys.lib.ActionContainer.prototype.runBuiltIn = function(root)
{
    var actionElements = this.getActionElements(root);
    this.builtInActions.forEach(function (action) {
        if (typeof actionElements[action] !== 'undefined' && actionElements[action].length) {
            import('./actions/' + action).then(function (module) {
                var actionObject = new DSsysLibActionClass.lib.ActionClass(action, actionElements[action]);
                var actionName = action.charAt(0).toUpperCase() + action.slice(1);
                new module['DSsysLibAction' + actionName].lib.actions[actionName](actionObject);
            });
        }
    });

    this.runLoad(root);
};
DSsys.lib.ActionContainer.prototype.runLoad = function(elmns)
{
    var load = this.getRegisteredAction('load');
    if (load) {
        var els = this.getEls(elmns);
        load(els);
    }
};
DSsys.lib.ActionContainer.prototype.getEls = function(elmns)
{
    if (elmns) {
        var els = [];
        var parseEls = function (el) {
            if (el instanceof Array || el instanceof NodeList) {
                for (var nel = 0; nel < el.length; nel++) {
                    parseEls(el[nel]);
                }
            } else {
                els.push(el);
            }
        };
        parseEls(elmns);
        return els;
    }
    return [document];
};
DSsys.lib.ActionContainer.prototype.runOnload = function(action, data)
{
    events.trigger(action, data);
};

export { DSsys as DSsysLibActionContainer };