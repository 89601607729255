var EventsCont = {};

EventsCont.events = {};

EventsCont.register = function(name, callback, context) {
    if (EventsCont.events[name]) {
        //just exists
    }
    EventsCont.events[name] = {
        fn: callback,
        context: (typeof context === 'undefined') ? null : context
    };
};

EventsCont.trigger = function() {
    var params = [];
    for (var i= 1; i < arguments.length; i++) {
        params.push(arguments[i]);
    }
    if (EventsCont.events[arguments[0]]) {
        return EventsCont.events[arguments[0]].fn.apply(EventsCont.events[arguments[0]].context, params);
    }
    //notExists
};

var events = {
    register: EventsCont.register,
    trigger: EventsCont.trigger
};

export { events };