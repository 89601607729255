var manifest;
var getFromManifest = function (url) {
    if (!manifest) {
        manifest = JSON.parse(document.querySelector('[data-js-dic]').getAttribute('data-manifest'));
    }
    if (typeof manifest[url] === 'undefined') {
        return '#';
    }
    return manifest[url];
};
var loadJs = function (inputUrl, callback) {
    var url = getFromManifest(inputUrl);
    if (url === '#') {
        return;
    }
    if (document.querySelector('script[src="'+url+'"]')) {
        callback();
    } else {
        var js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = url;
        js.onload = callback;
        document.body.appendChild(js);
    }
};

var loadCss = function (inputUrl, callback) {
    var url = getFromManifest(inputUrl);
    if (url === '#') {
        return;
    }
    if (document.head.querySelector('link[href="'+url+'"]')) {
        callback();
    } else {
        var css = document.createElement('link');
        css.rel = 'stylesheet';
        css.type = 'text/css';
        css.href = url;
        css.onload = callback;
        document.head.appendChild(css);
    }
};

var scriptLoader = {
    loadJs: loadJs,
    loadCss: loadCss
};

export { scriptLoader };