var ds = {
    children: function (children, selector) {
        var ret = [];
        for (var ch = 0; ch < children.length; ch++) {
            if (children[ch].matches(selector)) {
                ret.push(children[ch]);
            }
        }
        return ret;
    },
    ucfirst: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    lcfirst: function (string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    },
    trigger: function (el, etype, bubbles, cancelable) {
        var evt;
        switch (etype) {
            case 'click':
                evt = new MouseEvent('click', {
                    view: window,
                    bubbles: typeof bubbles === 'undefined' ? true : bubbles,
                    cancelable: typeof cancelable === 'undefined' ? true : cancelable
                });
                break;
            default:
                evt = new Event(etype, {
                    bubbles: typeof bubbles === 'undefined' ? true : bubbles,
                    cancelable: typeof cancelable === 'undefined' ? false : cancelable
                });
        }
        el.dispatchEvent(evt);
    },
    getPos: function (el) {
        var x = 0;
        var y = 0;
        var n = el;
        while (n) {
            x += n.offsetLeft + n.scrollLeft;
            y += n.offsetTop + n.scrollTop;
            n = n.offsetParent;
        }
        return {x:x, y:y};
    },
    getRelPos: function (el) {
        var getOffset = function (el) {
                var rect = el.getBoundingClientRect();
                var win = el.ownerDocument.defaultView;
                return {
                    y: rect.top + win.pageYOffset,
                    x: rect.left + win.pageXOffset
                };
            },
            parentOffset = { y: 0, x: 0 },
            offsetParent,
            offset,
            doc
        ;
		if (this.getCss(el, 'position') === 'fixed') {
			offset = el.getBoundingClientRect();
		} else {
			offset = getOffset(el);
			doc = el.ownerDocument;
			offsetParent = el.offsetParent || doc.documentElement;
			while (offsetParent
                && (offsetParent === doc.body || offsetParent === doc.documentElement)
                && this.getCss(offsetParent, 'position') === 'static'
            ) {
				offsetParent = offsetParent.parentNode;
			}
			if (offsetParent && offsetParent !== el && offsetParent.nodeType === 1) {
				parentOffset = getOffset(offsetParent);
				parentOffset.y += parseInt(this.getCss(offsetParent, 'border-top-width'));
				parentOffset.x += parseInt(this.getCss(offsetParent, 'border-left-width'));
			}
		}
		return {
			y: offset.y - parentOffset.y - parseInt(this.getCss(el, 'margin-top')),
			x: offset.x - parentOffset.x - parseInt(this.getCss(el, 'margin-left'))
		};
    },
    selectVal: function (sel, val) {
        if (typeof val === 'undefined') {
            if (sel.multiple) {
                var vals = [];
                for (var i = 0; i < sel.options.length; i++) {
                    if (sel.options[i].selected) {
                        vals.push(sel.options[i].value);
                    }
                }
                return vals;
            }
            return typeof sel.options[sel.selectedIndex] === 'undefined' ? null : sel.options[sel.selectedIndex].value;
        }
        if (sel.multiple) {
            var sVal = val;
        } else {
            var sVal = [val];
        }
        for (var i = 0; i < sel.options.length; i++) {
            for (var v = 0; v < sVal.length; v++) {
                if (sel.options[i].value === sVal[v]) {
                    sel.options[i].selected = true;
                } else {
                    sel.options[i].selected = false;
                }
            }
        }
    },
    getElVal: function (el) {
        return el.nodeName.toLowerCase() === 'select' ? this.selectVal(el) : el.value;
    },
    setElVal: function (el, val) {
        if (el.nodeName.toLowerCase() === 'select') {
            this.selectVal(el, val);
        } else {
            el.value = val;
        }
    },
    getCss: function (el, property) {
        var style = getComputedStyle(el);
        return style[this.toCamelCase(property)];
    },
    toCamelCase: function(str) {
        var spl = str.split('-');
        var ret = '';
        for (var i = 0; i < spl.length; i++) {
            if (i === 0) {
                ret += spl[i];
            } else {
                ret += this.ucfirst(spl[i]);
            }
        }
        return ret;
    },
    ev: {
        add: function (el, ev) {
            var evs = el.hasAttribute('data-ev') ? el.getAttribute('data-ev').split(' ') : [];
            evs.push(ev);
            el.setAttribute('data-ev', evs.join(' '));
        },
        has: function (el, ev) {
            return el.matches('[data-ev~="' + ev + '"]');
        }
    }
};

export { ds };