/* global import */
import { events } from './DSsys/dslib/events';
import { scriptLoader } from './DSsys/dslib/scriptLoader';
import { DSsysLibActionContainerBin } from './DSsys/dslib/action-ds/ActionContainerBin';
import { modules } from './modules/modules';

window.addEventListener('DOMContentLoaded', function () {

    events.register('infoMessage:show', function (data) {
        scriptLoader.loadCss('lib/notify.css', function () {
            import('./DSsys/dslib/notify/Notify').then(function (Module) {
                new Module.DSsysLibNotify.lib.Notify(data);
            });
        });
    });
    events.register('assets:load', function (data) {
        var loadCss = function () {
            if (data.css && data.css.length) {
                scriptLoader.loadCss(data.css.shift(), function () {
                    loadCss();
                });
            }
        };
        var loadJs = function () {
            if (data.js && data.js.length) {
                scriptLoader.loadJs(data.js.shift(), function () {
                    loadJs();
                });
            }
        };
        loadCss();
        loadJs();
    });
    DSsysLibActionContainerBin.lib.ActionContainerBin.registerAction('load', function (els) {
        els.forEach(function (elmn) {
            var modals = elmn.querySelectorAll('.modal');
            if (modals.length) {
                scriptLoader.loadCss('lib/modal.css', function () {
                    import('./DSsys/dslib/modal/Modal').then(function (Module) {
                        modals.forEach(function (modal) {
                            if (!modal.ds) {
                                modal.ds = {};
                            }
                            modal.ds.modal = new Module.DSsysLibModal.lib.Modal(modal);
                        });
                    });
                });
            }
            var calendars = elmn.querySelectorAll('[data-field~="calendar"]');
            if (calendars.length) {
                scriptLoader.loadCss('lib/calendar.css', function () {
                    import('./DSsys/dslib/calendar/Calendar').then(function (Module) {
                        calendars.forEach(function (calendar) {
                            if (!calendar.ds) {
                                calendar.ds = {};
                            }
                            calendar.ds.calendar = new Module.DSsysLibCalendar.lib.Calendar(calendar);
                        });
                    });
                });
            }

            var dateBtns = elmn.querySelectorAll('[data-field="dateBtn"]');
            if (dateBtns.length) {
                scriptLoader.loadCss('lib/calendar.css', function () {
                    import('./DSsys/dslib/calendar/Calendar').then(function (Module) {
                        dateBtns.forEach(function (calendar) {
                            if (!calendar.ds) {
                                calendar.ds = {};
                            }
                            var updateDate = function (el, dateString) {
                                var spl = dateString.split('-');
                                var parent = el.parentNode;
                                parent.querySelector('[data-field="year"]').value = spl[0];
                                parent.querySelector('[data-field="month"]').value = spl[1];
                                parent.querySelector('[data-field="day"]').value = spl[2];
                                el.value = dateString;
                            };
                            calendar.ds.calendar = new Module.DSsysLibCalendar.lib.Calendar(calendar, {
                                callbacks: {
                                    onInit: function (el, dateString) {
                                        updateDate(el, dateString);
                                    },
                                    onSelectDate: function (el, dateString) {
                                        updateDate(el, dateString);
                                        el.ds.calendar.hide();
                                    },
                                    onClose: function (el, closeFn) {
                                        closeFn();
                                        updateDate(el, '--');
                                        el.value = el.getAttribute('data-initValue');
                                    }
                                }
                            });
                        });
                    });
                });
            }
            var userThumbs = elmn.querySelectorAll('[data-field="userThumb"]');
            if (userThumbs.length) {
                userThumbs.forEach(function (th) {
                    th.setAttribute('src', document.querySelector('[data-js-dic="true"]').getAttribute('data-userThumb'));
                });
            }
            var imgs = elmn.querySelector('[data-field="preImg"]');
            if (imgs) {
                import('./modules/preLoadImg/preLoadImg').then(function (Module) {
                    var preImgs = new Module.preLoadImg('[data-field="preImg"]', function (el, url) {
                        setTimeout(function () {
                            el.addEventListener('load', function () {
                                el.closest('.photo').classList.add('photo--loaded');
                            });
                            el.addEventListener('error', function () {
                                el.closest('.photo').classList.add('photo--notLoaded');
                            });
                            el.setAttribute('src', url);
                            if (el.getAttribute('data-style')) {
                                el.setAttribute('style', el.getAttribute('data-style'));
                            }
                        }, (Math.floor(Math.random() * 400) + 600));
                    });
                    preImgs.run();
                });
            }
            var thumb = elmn.querySelector('[data-field="photoThumb"]');
            if (thumb) {
                import('./modules/misc/thumb').then(function (Module) {
                    Module.thumb.init(thumb);
                });
            }
            var captcha = elmn.querySelector('#captcha');
            if (captcha) {
                import('./modules/misc/captcha').then(function (Module) {
                    Module.captcha.init(captcha);
                });
            }
            var news = elmn.querySelector('[data-field="startNews"]');
            if (news) {
                import('./modules/misc/news').then(function (Module) {
                    Module.news.init(news);
                });
            }
            var searchForm = elmn.querySelector('[data-field="searchForms"]');
            if (searchForm) {
                import('./modules/misc/searchForm').then(function (Module) {
                    Module.searchForm.init();
                });
            }
            var addCommentForm = elmn.querySelector('[data-field="addCommentForm"]');
            if (addCommentForm) {
                import('./modules/misc/addComment').then(function (Module) {
                    Module.addComment.init(addCommentForm);
                });
            }
            var video = elmn.querySelector('.photoImg__file[data-yt]');
            if (video) {
                import('./modules/misc/showVideo').then(function (Module) {
                    Module.showVideo.init(video);
                });
            }
            var showNews = document.querySelector('[data-field="showStartNews"]:not([data-ev~="showStartNews"])');
            if (showNews) {
                showNews.addEventListener('click', function () {
                    showNews.setAttribute('data-ev', 'showStartNews');
                    showNews.parentNode.querySelector('[data-field="startNews"]').classList.toggle('hidden');
                });
            }
        });
        els.forEach(function (elmn) {
            var inputs = elmn.querySelectorAll('[data-ds-action~="inputWrapUpdate"]');
            inputs.forEach(function (el) {
                var fn = function () {
                    el.parentNode.querySelector('.inputWrap__el').innerHTML = el.type === 'number' ? el.value + '&nbsp;&nbsp;&nbsp;&nbsp;' : el.value;
                };
                el.addEventListener('change', function () {
                    fn();
                });
                el.addEventListener('keyup', function () {
                    fn();
                });
                fn();
            });
        });
        var adm = document.querySelector('[data-js-adm-dic="true"]');
        if (adm) {
            import('./modules/adm').then(function (Module) {
                Module.adm.init(els);
            });
        }
    });
    DSsysLibActionContainerBin.lib.ActionContainerBin.runBuiltIn();

    modules.run();
});