if (!window.DSsys) {
    var DSsys = {};
}
if (!DSsys.lib) {
    DSsys.lib = {};
}
DSsys.lib.ActionClass = function(name, els)
{
    this.name = name;
    this.els = els;
    this.prefix = 'data-ds-a-'+this.name+'-';
};
DSsys.lib.ActionClass.prototype.addEventsToElements = function(type, fn)
{
    var that = this;
    this.getElements().forEach(function (elmn) {
        if (elmn.matches('[data-js-' + that.name + '-ev~="' + type + '"]')) {
            return;
        }
        elmn.addEventListener(type, fn(elmn));
        var attr = 'data-js-' + that.name + '-ev';
        var attrVal = elmn.getAttribute(attr);
        elmn.setAttribute(attr, ((attrVal === null || attrVal === '') ? '' : ' ' + attrVal) + type);
    });
};
DSsys.lib.ActionClass.prototype.getElements = function()
{
    var selector = '[data-ds-action~="'+this.name+'"]:not([data-ds-action-auto="false"])';
    var els = [];
    this.els.forEach(function (el) {
        if (el.matches(selector)) {
            els.push(el);
        }
    });
    return els;
};
DSsys.lib.ActionClass.prototype.getParams = function(elmn, opts)
{
    opts = opts || {};
    var ret =  {};
    var attrs = this.filterAttributes(this.getNodeAttributes(elmn));
    if (attrs.parent) {
        var elems = attrs.parent.split(',');
        for (var i = 0; i < elems.length; i++) {
            var parentAttrs = this.filterAttributes(this.getNodeAttributes(elmn.closest(elems[i])));
            for (var name in parentAttrs) {
                ret[name] = parentAttrs[name];
            }
        }
        if (!opts.removeParent) {
            delete attrs.parent;
        }
    }
    for (var name in attrs) {
        ret[name] = attrs[name];
    }
    return ret;
};
DSsys.lib.ActionClass.prototype.filterAttributes = function(attrs)
{
    var ret = {};
    for (var name in attrs) {
        if (name.indexOf(this.prefix.toLowerCase()) === 0) {
            ret[name.replace(new RegExp(this.prefix.replace('-', '\\-'), 'i'), '')] = attrs[name];
        }
    }
    return ret;
};
DSsys.lib.ActionClass.prototype.getNodeAttributes = function(node)
{
    if (!node) {
        return {};
    }
    var
        i,
        attributeNodes = node.attributes,
        length = attributeNodes.length,
        attrs = {}
    ;

    for (i = 0; i < length; i++) {
        attrs[attributeNodes[i].name] = attributeNodes[i].value;
    }
    return attrs;
};


export { DSsys as DSsysLibActionClass };