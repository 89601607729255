import { DSsysLibActionContainer } from './ActionContainer';

if (!window.DSsys) {
    var DSsys = {};
}
if (!DSsys.lib) {
    DSsys.lib = {};
}
DSsys.lib.ActionContainerBin = new DSsysLibActionContainer.lib.ActionContainer();

export { DSsys as DSsysLibActionContainerBin };